.boleroBasicWidget.boleroBasicWidget {
    .section-elements {
        max-width: 1060px;
        margin: 45px auto;

        .items-wrapper {
            display: flex;
            flex-wrap: wrap;
            padding: 0 0 45px;
            border-bottom: 1px solid $bolero-gray;
            margin: 0 -15px;

            @include tablet-lg-to {
                padding: 30px 0;
                //margin: 30px 0;
            }

            .layout-wrapper {
                position: relative;
                color: $black;

                .layout-item {
                    transition: color 0.3s ease-in-out;


                    .imageBlock {
                        position: relative;
                        overflow: hidden;
                        display: flex;

                        &:after {
                            content: '';
                            display: block;
                            position: absolute;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            top: 0;
                            width: auto;
                            height: auto;
                            background: rgba($bolero-purple, 0.3);
                            opacity: 0;
                            transition: opacity 0.3s ease-in-out;
                        }

                        .bw-image {
                            width: 100%;

                            source {
                                display: block;
                            }

                            img {
                                width: 100%;
                                object-fit: cover;
                                height: 100%;
                                max-width: 100%;
                            }
                        }
                    }

                    .code-teaser_subtitle {
                        font-size: 18px;
                        line-height: 1.05;
                        font-family: $caslon-two-font;
                        font-weight: 700;
                        margin-bottom: 10px;


                        @include tablet-lg-to {
                            font-weight: 500;
                            font-size: 14px;
                        }
                    }

                    .code-sponsored_title {
                        display: none;
                        @include tablet-lg-to {
                            grid-row: 4;
                            grid-column: 2;
                            line-height: 1.05;
                            font-family: $caslon-two-font;
                            font-weight: 500;
                            font-size: 14px;
                            margin-top: 3px;
                            display: block;
                        }
                    }

                    .code-sponsored_by {
                        position: absolute;
                        background: $white;
                        top: 20px;
                        left: 50px;
                        display: flex;
                        align-items: center;
                        padding-left: 11px;
                        pointer-events: none;

                        @include tablet-lg-to {
                            display: none;
                        }

                        > .image-wrapper {
                            order: 2;
                            flex: 0 1 115px;
                            height: 36px;
                            width: 115px;
                            position: relative;
                            overflow: hidden;

                            img {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                object-fit: cover;
                                width: 100%;
                                height: 100%;
                            }
                        }

                        > .alternative-teaser-title {
                            order: 1;
                        }

                        > .alternative-teaser-text {
                            display: none;
                        }
                    }

                    .bw-title {
                        .title {
                            font-size: 43px;
                            line-height: 1;
                            font-family: $caslon-two-font;
                            text-transform: uppercase;
                            font-weight: 400;
                            margin: 0;
                            word-break: break-word;
                        }
                    }

                    .lead {
                        font-size: 18px;
                        line-height: 1.5;
                        margin-top: 14px;

                        @include tablet-lg-to {
                            font-size: 22px;
                        }

                        p {
                            margin: 0;
                        }
                    }

                    .taxonomy-wrapper {
                        font-size: 16px;
                        line-height: 1.1531;
                        font-family: $relative-book-font;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                        margin-top: 20px;

                        .single-category {
                            margin: 0 10px;

                            @include tablet-lg-to {
                                margin: 0 10px 0 0;
                            }
                        }
                    }

                    .gradient-overlay {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        width: auto;
                        height: auto;

                        a {
                            display: block;
                            height: 100%;
                        }
                    }

                    &:hover, &:focus {
                        color: $bolero-purple;

                        .imageBlock {
                            &:after {
                                opacity: 1;
                            }
                        }
                    }
                }
            }

            .layout-wrapper.article-big-wrapper {
                flex: 1 1 100%;
                width: 100%;
                max-width: 100%;

                .layout-item {
                    text-align: center;
                    padding-bottom: 45px;
                    grid-template-columns: 68.86% auto;
                    display: grid;
                    align-items: center;
                    grid-template-rows: 1fr max-content max-content max-content max-content max-content 1fr;
                    position: relative;
                    column-gap: 40px;
                    border-bottom: 1px solid $bolero-gray;

                    @include tablet-lg-to {
                        align-items: normal;
                        grid-template-rows: max-content;
                        text-align: left;
                        grid-template-columns: 100%;
                        column-gap: 0;
                        padding-bottom: 30px;
                    }

                    &:after {
                        display: block;
                        content: '';
                        grid-column: 2;
                        grid-row: 8;

                        @include tablet-lg-to {
                            grid-column: 1;
                        }
                    }

                    &:before {
                        display: block;
                        content: '';
                        grid-column: 2;
                        grid-row: 1;

                        @include tablet-lg-to {
                            grid-column: 1;
                        }
                    }

                    .imageBlock {
                        grid-auto-flow: row;
                        grid-column: 1;
                        grid-row: 1 / 8;
                        max-width: 730px;
                        max-height: 388px;

                        @include tablet-lg-to {
                            grid-row: 1;
                            max-width: 100%;
                            min-height: 250px;
                            margin-bottom: 30px;
                        }
                    }

                    .code-teaser_subtitle {
                        grid-column: 2;
                        grid-row: 2;

                        @include tablet-lg-to {
                            grid-column: 1;
                        }
                    }

                    .code-sponsored_title {
                        display: none;
                    }

                    .code-sponsored_by {
                        position: absolute;
                        background: $white;
                        top: 0;
                        left: 0;
                        display: flex;
                        align-items: center;
                        padding-left: 11px;

                        > .image-wrapper {
                            order: 2;
                            flex: 0 1 115px;
                            height: 36px;
                            width: 115px;
                        }
                    }

                    .bw-title {
                        grid-column: 2;
                        grid-row: 3;

                        @include tablet-lg-to {
                            grid-column: 1;
                        }

                        .title {
                            @include tablet-lg-to {
                                font-size: 36px;
                            }
                        }
                    }

                    .lead {
                        grid-column: 2;
                        grid-row: 4;

                        @include ringCutText(4);

                        @include tablet-lg-to {
                            grid-column: 1;
                            margin-top: 21px;

                            @include ringCutText(6);
                        }
                    }

                    .taxonomy-wrapper {
                        grid-column: 2;
                        grid-row: 5;

                        @include tablet-lg-to {
                            grid-column: 1;
                            font-size: 18px;
                            margin-top: 24px;
                        }
                    }
                }
            }

            .layout-wrapper:not(.article-big-wrapper) {
                margin-top: 35px;
                flex: 0 0 50%;
                max-width: 50%;

                @include tablet-lg-to {
                    flex: 0 0 100%;
                    max-width: 100%;
                    margin-top: 25px;
                }

                .layout-item {
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;
                    text-align: center;

                    @include tablet-lg-to {
                        text-align: left;
                        padding-bottom: 0;
                        grid-template-columns: 117px auto;
                        display: grid;
                        align-items: center;
                        grid-template-rows: 1fr max-content max-content max-content max-content max-content 1fr;
                        position: relative;
                        column-gap: 15px;

                        &:after {
                            display: block;
                            content: '';
                            grid-column: 2;
                            grid-row: 8;
                        }

                        &:before {
                            display: block;
                            content: '';
                            grid-column: 2;
                            grid-row: 1;
                        }
                    }

                    .imageBlock {
                        order: 1;
                        flex: 1 1 100%;
                        width: 100%;
                        margin-bottom: 27px;
                        display: inline-flex;
                        max-width: 100%;
                        min-height: 349px;

                        @include tablet-lg-to {
                            grid-auto-flow: row;
                            grid-column: 1;
                            grid-row: 1 / 8;

                            max-width: 117px;
                            max-height: 117px;
                            min-height: 117px;
                            margin-bottom: 0;
                        }
                    }

                    .code-teaser_subtitle {
                        order: 2;

                        @include tablet-lg-to {
                            grid-row: 2;
                            grid-column: 2;

                            margin-bottom: 7px;
                        }
                    }

                    .bw-title {
                        order: 3;

                        @include tablet-lg-to {
                            grid-row: 3;
                            grid-column: 2;
                        }

                        .title {
                            @include tablet-lg-to {
                                font-size: 24px;
                            }
                        }
                    }

                    .lead {
                        order: 4;

                        @include tablet-lg-to {
                            display: none;
                        }

                        @include ringCutText(3);
                    }

                    .taxonomy-wrapper {
                        order: 5;

                        @include tablet-lg-to {
                            grid-row: 5;
                            grid-column: 2;

                            font-size: 14px;
                            margin-top: 15px;
                        }
                    }
                }
            }
        }

        .big-elements-1 {
            .layout-wrapper:nth-child(2n+2) {
                .layout-item {
                    padding: 20px 50px 25px 0;
                    border-right: 1px solid $bolero-gray;

                    @include tablet-lg-to {
                        padding: 0;
                        border: none;
                    }
                }
            }

            .layout-wrapper:nth-child(2n+3) {
                .layout-item {
                    padding: 20px 0 25px 50px;

                    @include tablet-lg-to {
                        padding: 0;
                    }
                }
            }
        }

        .big-elements-0 {
            .layout-wrapper:nth-child(2n+1) {
                .layout-item {
                    padding: 20px 50px 25px 0;
                    border-right: 1px solid $bolero-gray;

                    @include tablet-lg-to {
                        padding: 0;
                        border: none;
                    }
                }
            }

            .layout-wrapper:nth-child(2n) {
                .layout-item {
                    padding: 20px 0 25px 50px;

                    @include tablet-lg-to {
                        padding: 0;
                    }
                }
            }
        }
    }

    &.bigImageRight {
        .section-elements {
            .items-wrapper {
                .layout-wrapper.article-big-wrapper {
                    .layout-item {
                        grid-template-columns: auto 68.86%;

                        @include tablet-lg-to {
                            padding-bottom: 30px;
                            grid-template-columns: 100%;
                        }

                        &:after {
                            display: block;
                            content: '';
                            grid-column: 1;
                            grid-row: 8;
                        }

                        &:before {
                            display: block;
                            content: '';
                            grid-column: 1;
                            grid-row: 1;
                        }

                        .imageBlock {
                            grid-auto-flow: row;
                            grid-column: 2;
                            grid-row: 1 / 8;
                            max-width: 730px;
                            max-height: 388px;

                            @include tablet-lg-to {
                                grid-column: 1;
                                grid-row: 1;
                                max-width: 100%;
                                min-height: 250px;
                                margin-bottom: 30px;
                            }
                        }

                        .code-teaser_subtitle {
                            grid-column: 1;
                            grid-row: 2;
                        }

                        .bw-title {
                            grid-column: 1;
                            grid-row: 3;
                        }

                        .lead {
                            grid-column: 1;
                            grid-row: 4;

                            @include ringCutText(4);
                        }

                        .taxonomy-wrapper {
                            grid-column: 1;
                            grid-row: 5;
                        }
                    }
                }
            }
        }
    }

    &.bigImageFull {
        .section-elements {
            .items-wrapper {
                .layout-wrapper.article-big-wrapper {
                    .layout-item {
                        grid-template-rows: max-content;
                        grid-template-columns: 100%;

                        @include tablet-lg-to {
                            padding-bottom: 30px;
                        }

                        &:after {
                            display: none;
                        }

                        &:before {
                            display: none;
                        }

                        .imageBlock {
                            grid-auto-flow: row;
                            grid-column: 1;
                            grid-row: 1;
                            max-width: 1060px;
                            max-height: 531px;
                            margin-bottom: 63px;

                            @include tablet-lg-to {
                                max-width: 100%;
                                min-height: 250px;
                                margin-bottom: 30px;

                            }
                        }

                        .code-teaser_subtitle {
                            grid-column: 1;
                            grid-row: 2;

                            @include desktop-xl-from {
                                margin-bottom: 16px;
                            }
                        }

                        .bw-title {
                            grid-column: 1;
                            grid-row: 3;

                            .title {
                                @include desktop-xl-from {
                                    font-size: 78px;
                                }
                            }
                        }

                        .lead {
                            grid-column: 1;
                            grid-row: 4;

                            @include desktop-xl-from {
                                font-size: 26px;
                                line-height: 1.34;
                                margin-top: 18px;
                            }

                            @include ringCutText(4);
                        }

                        .taxonomy-wrapper {
                            grid-column: 1;
                            grid-row: 5;
                        }
                    }
                }
            }
        }
    }

    .layout-item.layout-item.layout-item {

        .imageBlock {
            &:after {
                background: rgba($bolero-purple, 0.3);
                z-index: 2;
                pointer-events: none;
            }
        }

        &:hover, &:focus {
            color: $bolero-purple;
        }
    }
}
